import axios from 'axios';

const baseUrl = () => {
  switch (window.location.host) {
    case 'localhost:3001':
      return 'http://localhost:3000';
    case 'dev.indexapro.com.br':
      return 'https://gf0wna6ucb.execute-api.us-east-1.amazonaws.com/dev';
    default:
      return 'https://m13yu74ksa.execute-api.us-east-1.amazonaws.com/prod';
  }
};

export const staticAssetsUrl = () => {
  switch (window.location.host) {
    case 'localhost:3001':
      return 'https://dev-static-assets.indexapro.com.br';
    case 'dev.indexapro.com.br':
      return 'https://dev-static-assets.indexapro.com.br';
    default:
      return 'https://static-assets.indexapro.com.br';
  }
};

export const mercadoPagoToken = () => {
  switch (window.location.host) {
    case 'localhost:3001':
      return 'TEST-52ae8f5c-9efa-4695-a3ae-a01e8fa9b99e';
    case 'dev.indexapro.com.br':
      return 'TEST-52ae8f5c-9efa-4695-a3ae-a01e8fa9b99e';
    default:
      return 'APP_USR-ccefa65a-5d05-4ea9-9cfa-b2a7fedc32e3';
  }
};

const api = axios.create({
  baseURL: baseUrl(),
});

export const getCepAddress = async cep => {
  const cepAddress = await api.get(`https://viacep.com.br/ws/${cep}/json/`);

  return cepAddress.data;
};

export const getTemplateList = async () => {
  const templateList = await api.get(`${staticAssetsUrl()}/indexa-mfe-library/core-shell/indexa-templates.json`);

  return templateList.data;
};

export default api;
