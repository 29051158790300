import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { handleSiteUrl } from './utils';

export const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      }, { rootMargin },
    );

    // eslint-disable-next-line no-unused-expressions
    element.current && observer.observe(element.current);

    return () => observer.unobserve(element.current);
  }, []);

  return isVisible;
};

export const useSession = () => {
  const { token } = useSelector(state => state.session.data);

  const isLoggedIn = localStorage.getItem('access-token') || !!token;

  return isLoggedIn;
};

export const useSitePrefix = () => {
  const { sslRequests, domain } = useSelector(state => state.user.data);

  const checkSslRequest = requestedDomain => {
    if (sslRequests && sslRequests.length > 0) {
      const sslRequestData = sslRequests.filter(ssl => ssl.domain === requestedDomain);

      if (sslRequestData[0]) {
        return sslRequestData[0].status;
      }
      return null;
    }

    return null;
  };
  const indexaDomainSsl = checkSslRequest(handleSiteUrl('', domain, ''));
  const sitePrefix = indexaDomainSsl === 'finished' ? 'https' : 'http';

  return sitePrefix;
};
