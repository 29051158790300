import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { getCookie, setCookie } from '../../utils/utils';

import { Container } from './styles';

const CookiesConsent = () => {
  const [consentAccepted, setConsentAccepted] = useState(false);

  const handleConsent = () => {
    setCookie('consentAccepted', true);
    setConsentAccepted(true);
  };

  if (!!getCookie('consentAccepted') === true) {
    return <></>;
  }

  if (consentAccepted) {
    return <></>;
  }

  return (
    <Container>
      <p>
        Nosso site utiliza cookies para te proporcionar uma melhor experiência. Ao acessar o site da Indexa, você concorda com a nossa
        <a href="/politica-de-privacidade" target="_blank">Política de Privacidade</a>
        e
        <a href="/politica-de-cookies" target="_blank">Cookies</a>
        <Button
          size="small"
          style={{
            color: '#fff', border: '2px solid #fff', height: '1.5rem', marginLeft: '0.5rem', textTransform: 'none',
          }}
          onClick={() => handleConsent()}
        >
          Entendi
        </Button>
      </p>
    </Container>
  );
};

export default CookiesConsent;
