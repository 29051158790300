import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const handleRedirectUrl = routeProps => <Redirect to={`/login?postLoginRedirect=${routeProps.location.pathname}`} />;

export const PrivateRoute = props => (localStorage.getItem('access-token') ? (
  <Route {...props} />
) : (
  handleRedirectUrl(props)
));

export const PrivateAdminRoute = props => {
  if (localStorage.getItem('access-token')) {
    return <Route {...props} />;
  }

  return handleRedirectUrl(props);
};
