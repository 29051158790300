import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Loading from '../Loading';

const NotFoundLoader = () => {
  const [buttonIsVisible, setButtonIsVisible] = useState(false);

  useEffect(() => {
    function addButton() {
      setButtonIsVisible(true);
    }

    const intervalId = setInterval(addButton, 10000);

    return () => clearInterval(intervalId);
  }, []);

  function redirectToHome() {
    window.location.href = '/';
  }

  return (
    <>
      <Loading>
        {buttonIsVisible && (
          <>
            <br />
            <span>Ops, página não encontrada.</span>
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={() => redirectToHome()}
            >
              Voltar para Página Inicial
            </Button>
          </>
        )}
      </Loading>
    </>
  );
};

export default NotFoundLoader;
