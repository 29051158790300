import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import { Actions as SessionActions } from '../actions/session';
import { Actions as UserActions } from '../actions/user';
import { showToast } from '../../components/Toast';
import { history } from '../../routes';
import { getCookie } from '../../utils/utils';

export function* loginUser(action) {
  try {
    const { email, password, redirect } = action.session;

    const campaign = getCookie('utm_campaign') || '';
    const source = getCookie('utm_source') || '';

    const response = yield call(api.post, '/session', {
      email,
      password,
      campaign,
      source,
    });

    localStorage.setItem('access-token', response.data.token);

    yield put(SessionActions.loginUserSuccess(response.data));
    yield put(UserActions.getUserRequest());

    if (redirect) {
      switch (response.data.user.permission) {
        case 'admin':
          history.push('/admin/home');
          break;
        case 'partner':
          history.push('/parceiro/home');
          break;

        default:
          if (!response.data.user.onboardingFinished) {
            history.push('/criar-site?resume=true');
          } else {
            const urlParams = new URLSearchParams(window.location.search);
            const postLoginRedirect = urlParams.get('postLoginRedirect');
            history.push(postLoginRedirect || '/dashboard/home');
          }
          break;
      }
    }
  } catch (error) {
    let errorMessage = 'Usuário ou senha incorretos.';

    if (error?.response?.data?.error?.message) {
      errorMessage = error.response.data.error.message;
    }

    showToast(errorMessage, 'error');
    yield put(SessionActions.loginUserError(errorMessage));
  }
}

export function* logoutUser() {
  localStorage.removeItem('access-token');
  yield put(SessionActions.logoutUserSuccess());

  history.push('/');
}
