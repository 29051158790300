import styled from 'styled-components';

export const Container = styled.div`
  .wrapper {
    position: fixed;
    top: 50%;
    left: 30px;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }

  ul {
    list-style: none;
  }

  ul li {
    width: 50px;
    height: 50px;
    position: relative;
    background: #e59500;
    margin: 10px 0;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }

  ul li svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #fff;
  }

  ul li.facebook{
    background: #3b5998;
  }

  ul li.instagram{
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  }

  ul li.whatsapp{
    background: #4dc247;
  }

  ul li.youtube{
    background: #bb0000;
  }

  ul li.facebook div.slider{
    background: #627aac;
  }

  ul li.instagram div.slider{
    background:  #ed9576;
  }

  ul li.whatsapp div.slider{
    background: #82d47e;
  }

  ul li.youtube div.slider{
    background: #ff3232;
  }

  .slider {
    content: "";
    position: absolute;
    top: 0;
    left: 51px;
    width: 0px;
    height: 50px;
    background: #eebb5c;
    border-radius: 3px;
    transition: all 0.5s 0.3s ease;
  }

  .slider p {
    font-family: arial;
    // text-transform: uppercase;
    font-size: 16px;
    font-weight: 900;
    color: #fff;
    text-align: center;
    line-height: 50px;
    opacity: 0;
    transition: all 0.6s ease;
  }

  ul li:hover .slider {
    width: 180px;
    transition: all 0.5s ease;
  }

  ul li:hover .slider p {
    opacity: 1;
    transition: all 1s 0.2s ease;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;
