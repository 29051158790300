import React from 'react';

import { CircularProgress } from '@material-ui/core';
import { Spinner } from './styles';

const Loading = ({ children }) => (
  <Spinner>
    <CircularProgress />
    {children}
  </Spinner>
);

export default Loading;
