/* eslint-disable react/prop-types */
import React from 'react';
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
  FaArrowDown,
  FaBars,
  FaChartLine,
  FaChartPie,
  FaCheckCircle,
  FaChevronLeft,
  FaCloud,
  FaCode,
  FaComment,
  FaExclamation,
  FaFacebook,
  FaFileInvoice,
  FaFilter,
  FaHandshake,
  FaHome,
  FaIndent,
  FaInstagram,
  FaLock,
  FaMobile,
  FaSearch,
  FaSpinner,
  FaTrash,
  FaUpload,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';

const defaultSize = 1;

export const YoutubeIcon = ({ size, color }) => (
  <FaYoutube color={color} size={`${size || defaultSize}rem`} />
);

export const AdvancedIcon = ({ size, color }) => (
  <FaCode color={color} size={`${size || defaultSize}rem`} />
);

export const WhatsAppIcon = ({ size, color }) => (
  <FaWhatsapp color={color} size={`${size || defaultSize}rem`} />
);

export const FacebookIcon = ({ size, color }) => (
  <FaFacebook color={color} size={`${size || defaultSize}rem`} />
);

export const InstagramIcon = ({ size, color }) => (
  <FaInstagram color={color} size={`${size || defaultSize}rem`} />
);

export const LockIcon = ({ size, color }) => (
  <FaLock color={color} size={`${size || defaultSize}rem`} />
);

export const MessageIcon = ({ size, color }) => (
  <FaComment color={color} size={`${size || defaultSize}rem`} />
);

export const HandIcon = ({ size, color }) => (
  <FaHandshake color={color} size={`${size || defaultSize}rem`} />
);

export const CheckIcon = ({ size, color }) => (
  <FaCheckCircle color={color} size={`${size || defaultSize}rem`} />
);

export const CloudIcon = ({ size, color }) => (
  <FaCloud color={color} size={`${size || defaultSize}rem`} />
);

export const TrashIcon = ({ size }) => (
  <FaTrash size={`${size || defaultSize}rem`} />
);

export const ImageUploadIcon = ({ size }) => (
  <FaUpload size={`${size || defaultSize}rem`} />
);

export const ArrowDownNormalIcon = ({ size }) => (
  <FaArrowDown size={`${size || defaultSize}rem`} />
);

export const PlotsIcon = ({ size }) => (
  <FaChartPie size={`${size || defaultSize}rem`} />
);

export const HomeIcon = ({ size }) => (
  <FaHome size={`${size || defaultSize}rem`} />
);

export const OperationsIcon = ({ size }) => (
  <FaFileInvoice size={`${size || defaultSize}rem`} />
);

export const MenuIcon = ({ size }) => <FaBars size={`${size}rem`} />;

export const ArrowUpIcon = ({ size }) => (
  <FaArrowAltCircleUp size={`${size || defaultSize}rem`} />
);

export const ArrowDownIcon = ({ size }) => (
  <FaArrowAltCircleDown size={`${size || defaultSize}rem`} />
);

export const SearchIcon = ({ size }) => (
  <FaSearch size={`${size || defaultSize}rem`} />
);

export const FilterIcon = ({ size }) => (
  <FaFilter size={`${size || defaultSize}rem`} />
);

export const LeftIcon = ({ size }) => (
  <FaChevronLeft size={`${size || defaultSize}rem`} />
);

export const SpinnerIcon = ({ size }) => (
  <FaSpinner size={`${size || defaultSize}rem`} />
);

export const InfoIcon = ({ size }) => (
  <FaExclamation size={`${size || defaultSize}rem`} />
);

export const MobileIcon = ({ size }) => (
  <FaMobile size={`${size || defaultSize}rem`} />
);

export const ChartLineIcon = ({ size }) => (
  <FaChartLine size={`${size || defaultSize}rem`} />
);

export const SeoIcon = ({ size }) => (
  <FaIndent size={`${size || defaultSize}rem`} />
);
