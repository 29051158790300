import styled from 'styled-components';

export const Container = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: block;
    .wrapper {
      //transform: translate(-50%, -100%);
      z-index: 1000;
    }

    ul {
      display: flex;
      list-style: none;
    }

    ul li {
      width: 50px;
      height: 50px;
      position: relative;
      background: #e59500;
      margin: 10px 5px;
      cursor: pointer;
      border-radius: 3px;
      box-shadow: 0 0 10px rgba(0,0,0,0.3);
    }

    ul li svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
      color: #fff;
    }

    ul li.facebook{
      background: #3b5998;
    }

    ul li.instagram{
      background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    }

    ul li.whatsapp{
      background: #4dc247;
    }

    ul li.youtube{
      background: #bb0000;
    }
  }
`;
