import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  background-color: #272334;
  color: #fff;
  font-size: 0.8rem;

  > p {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    text-align: center;
    min-height: 1.5rem;
    transition: transform 0.5s ease;
    margin: 1rem;
    line-height: 1.6;

    > a {
      white-space: nowrap;
      margin: 0 0.2rem;
      color: #2867B9;
      cursor: pointer;
    }
  }
`;
