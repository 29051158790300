import { call, put } from 'redux-saga/effects';

import { Actions as UserActions } from '../actions/user';
import { getUser as getUserRequest } from '../../services/user';
import { history } from '../../routes';

export function* getUser() {
  try {
    const response = yield call(getUserRequest);

    yield put(UserActions.getUserSuccess(response));

    if (!response.onboardingFinished && !window.location.pathname.includes('criar-site')) {
      history.push('/criar-site?resume=true');
    }
  } catch (error) {
    yield put(UserActions.getUsuarioError('Ocorreu algum erro, por favor, tente novamente'));
  }
}
