import {
  Avatar, Button, DialogContent, Paper,
} from '@material-ui/core';
import { Animated } from 'react-animated-css';
import styled, { keyframes } from 'styled-components';

export const MainContainer = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;

  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;

export const Content = styled(Animated)`
  flex:1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 12rem;

  @media (max-width: 1250px) {
    margin: 2rem;
  }
`;

export const ContentCard = styled(Paper)`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 3rem;
  //justify-content: center;
  //align-items: flex-start;
  //height: 15rem;

  img {
    position: absolute;
    top: 0;
    right: 1rem;
    height: 7rem;
    /* -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222); */
  }

  iframe {
    border-radius: 5px;
    margin: 0.8rem 0;
  }
`;

export const ContentInfos = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: flex-start;
`;

export const DescriptionText = styled.div`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);

  span {
    margin-left: 0.2rem;
    font-size: 1rem;
    letter-spacing: 0.03333em;
    text-align: center;
  }
`;

export const SectionConatiner = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  margin: 2rem 8rem;

  h1 {
    text-align: center;
  }

  @media (max-width: 768px) {
    margin: 2rem 3rem;
  }
`;

export const FeaturesContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Feature = styled(Animated)`
  //width: 100%;
  flex: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 12rem;
  margin-top: 2rem;
  margin-bottom: 1rem;

  > div {
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      font-size: 1.2rem;
      font-weight: bold;
      margin: 1rem 0;
      text-align: center;
    }

    span {
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
    }

    @media (max-width: 1024px) {
      width: 100%;
    }
  }
`;

export const Description = styled.div`
  display: flex;
  width: 60rem;

  h1 {
    color: #fff;
    font-size: 5rem;
    font-weight: lighter;
    letter-spacing: 1px;
    text-align: center;

    @media (max-width: 1024px) {
      font-size: 4rem;
      margin: 0 1rem 2rem 1rem;
    }

    @media (max-width: 768px) {
      font-size: 2rem;
      margin: 0 1rem 2rem 1rem;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const gradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const BgImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(-45deg, #201b28, #2867b9, #201b28, #6c96ce);
  background-size: 400% 400%;
  animation-name: ${gradient};
  animation-duration: 40s;
  animation-iteration-count: infinite;
  z-index: -1000;
`;

const Bounce = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-20px);
  }
`;

export const ButtonMore = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  animation: ${Bounce} 0.75s infinite alternate;
  margin-bottom: 1rem;
  border-radius: 10px;
  color: white;
  background-color: transparent;
  //width: 8.5rem;
  //height: 5.25rem;
  cursor: pointer;
`;

export const ButtonMoreContent = styled(Animated)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const HowItWorksSection = styled.section`
  background: #272334;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
`;

export const HowItWorksItem = styled.div`
  //width: 20rem;
  flex: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 14rem;
  margin-top: 2rem;

  > img {
    height: 15rem;
  }

  > div {
    width: 25rem;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 2.5rem;
      color: #2867B9;
      text-align: start;
      margin-bottom: 0;
    }

    label {
      font-size: 1.3rem;
      //font-weight: bold;
      margin: 1rem 0 1rem 0;
    }

    span {
      // text-align: center;
      font-weight: lighter;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    margin: 2rem 2rem;
  }
`;

export const HowItWorksContent = styled(Animated)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const CustomStep = styled(Avatar)`
  && {
    background-color: #2867B9;
  }
`;

export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10rem;

  hr {
    width: 100%;
    margin: 2rem 0;
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 4rem;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  @media (max-width: 1024px) {
    margin: 0;
  }
`;

export const FeedbackItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FeedbackInfos = styled.div`
  display: flex;
  margin-bottom: 1rem;

  img {
    /* height: 6rem;
    background-color: black;
    border-radius: 50%;*/
    margin: 0 1rem;
    border: 5px solid #fff;
    box-sizing: border-box;
    height: 6rem;
    border-radius: 50%;
    width: 6rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);

    @media (max-width: 768px) {
      display: none;
    }
  }

  span {
    color: rgba(0,0,0,0.6);
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FeedbackCompany = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.alignItens || 'flex-end'} ;

  a {
    cursor: pointer;
  }
`;

export const FeedbackName = styled.span`
  font-weight: bold;
`;

export const FeedbackPosition = styled.span`
  color: rgba(0,0,0,0.6);
  font-style: italic;
  font-size: 0.8rem;
`;

export const FeedbackCompanyName = styled.span`
  color: rgb(40, 103, 185);
  font-size: 0.9rem;
`;

export const HomeButton = styled(Button)`
  && {
    height: 2.5rem;

    @media (max-width: 768px) {
      font-size: 0.75rem;
    }
  }
`;
