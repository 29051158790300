import styled from 'styled-components';

export const Spinner = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  //MUI defines 1300, we defined 1400 Xd
  z-index: 1400;
`;

export const Background = styled.div`
  position: absolute;
  opacity: 0.2;
  background-color: #000;
  height: 100%;
  width: 100%;
`;
