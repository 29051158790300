import React from 'react';
import { Route } from 'react-router-dom';
import HelmetTags from '../components/HelmetTags';
import { PrivateRoute } from './PrivateRoute';

export const CustomRoute = ({
  title = 'Criador de sites Indexa', content = 'Crie seu site de maneira rápida e fácil', privateRoute, ...props
}) => (
  <>
    <HelmetTags
      title={title}
      content={content}
    />
    {privateRoute ? (
      <PrivateRoute
        {...props}
      />
    ) : (
      <Route
        {...props}
      />
    )}
  </>
);
