import styled from 'styled-components';

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #272334;
  width: 100%;
  height: 10rem;

  @media (max-width: 1024px) {
    padding-top: 2rem;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10rem;
  font-size: 0.8rem;
  background: #272334;
  color: #fff;

  div {
    padding: 0 1rem;
    border-right: 1px solid #ccc;
    text-align: center;

    &:last-child {
      border: 0;
    }
  }
`;
