import { Button } from '@material-ui/core';
import React from 'react';

import HeaderMenu from '../../Header/Menu';
import { HeaderActionMenu, HeaderContainer, HeaderContent } from './styles';
import HeaderSiteMenu from './Menu';
import { history } from '../../../routes';
import { staticAssetsUrl } from '../../../services/api';

const Header = () => {
  const isLoggedIn = localStorage.getItem('access-token');

  const handleScroll = location => {
    const reasons = document.querySelector('#reasons');
    const reasonsHeight = reasons?.offsetHeight;
    const howItWorksOffSet = window.innerHeight + reasonsHeight;

    const howItWorks = document.querySelector('#how-it-works');
    const howItWorksHeight = howItWorks?.offsetHeight;
    const clientsOffSet = window.innerHeight + reasonsHeight + howItWorksHeight;

    switch (location) {
      case 'howItWorks':
        window.scrollTo({ top: howItWorksOffSet, behavior: 'smooth' });
        break;
      case 'clients':
        window.scrollTo({ top: clientsOffSet, behavior: 'smooth' });
        break;

      default:
        break;
    }
  };

  return (
    <HeaderContainer>
      <HeaderContent>
        <HeaderSiteMenu />
        <a href="/">
          <img src={`${staticAssetsUrl()}/indexa-mfe-library/shared-assets/indexaLogo.png`} alt="Logo Indexa" />
        </a>
        <HeaderActionMenu>
          <Button onClick={() => window.scrollTo({ top: window.innerHeight - 64, behavior: 'smooth' })} color="inherit">
            Por que a Indexa?
          </Button>
          <Button onClick={() => handleScroll('howItWorks')} color="inherit">Como funciona?</Button>
          <Button onClick={() => handleScroll('clients')} color="inherit">Clientes</Button>
        </HeaderActionMenu>
        {isLoggedIn ? (<HeaderMenu />) : (
          <Button
            onClick={() => history.push('/login')} style={{ marginRight: '1rem' }}
            color="inherit"
          >
            Entrar
          </Button>
        )}
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
