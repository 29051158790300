import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Button } from '@material-ui/core';
import { Container } from './styles';

export default function HeaderSiteMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleScroll = location => {
    const reasons = document.querySelector('#reasons');
    const reasonsHeight = reasons?.offsetHeight;
    const howItWorksOffSet = window.innerHeight + reasonsHeight;

    const howItWorks = document.querySelector('#how-it-works');
    const howItWorksHeight = howItWorks?.offsetHeight;
    const clientsOffSet = window.innerHeight + reasonsHeight + howItWorksHeight;

    switch (location) {
      case 'howItWorks':
        window.scrollTo({ top: howItWorksOffSet, behavior: 'smooth' });
        break;
      case 'clients':
        window.scrollTo({ top: clientsOffSet, behavior: 'smooth' });
        break;

      default:
        break;
    }
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <IconButton
        aria-label="account of current user"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          handleClose();
        }}
        >
          <Button onClick={() => window.scrollTo({ top: window.innerHeight - 64, behavior: 'smooth' })} color="inherit">
            Por que a Indexa?
          </Button>
        </MenuItem>
        <hr />
        <MenuItem onClick={() => {
          handleClose();
        }}
        >
          <Button onClick={() => handleScroll('howItWorks')} color="inherit">Como funciona?</Button>
        </MenuItem>
        <hr />
        <MenuItem onClick={() => {
          handleClose();
        }}
        >
          <Button onClick={() => handleScroll('clients')} color="inherit">Clientes</Button>
        </MenuItem>
      </Menu>
    </Container>
  );
}
