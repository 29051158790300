import React from 'react';
import { useSelector } from 'react-redux';

import { CustomAvatar } from './styles';

const HeaderAvatar = () => {
  const { name } = useSelector(state => state.user.data);

  const getInitials = nameString => {
    if (nameString) {
      const nameSplited = nameString.toUpperCase().split(' ');

      const firstName = nameSplited[0];
      const lastName = nameSplited[1] ? nameSplited.pop() : null;
      // const [firstName, lastName] = string.toUpperCase().split(' ');
      const initials = firstName.substring(0, 1);
      return lastName
        ? initials.concat(lastName.substring(0, 1))
        : initials.concat(firstName.substring(1, 2));
    }

    return '';
  };

  return (
    <CustomAvatar>{getInitials(name)}</CustomAvatar>
  );
};

export default HeaderAvatar;
