export const removeSpecialCharacters = text => text.normalize('NFD').replace(/[^a-zA-Z0-9s]/g, '').toLowerCase();

export const setMaxLength = (text, lengthLimit) => text.toString().slice(0, lengthLimit);

export const configurationFormatter = (dotNotationConfiguration, addColors = false) => {
  const properties = dotNotationConfiguration;

  // Handling colors
  if (addColors) {
    properties['colors.primary'] = dotNotationConfiguration['colors.details.primary'].hex;
    properties['colors.secondary'] = dotNotationConfiguration['colors.details.secondary'].hex;
  }

  const configuration = {};

  Object.keys(properties).forEach(key => {
    const parts = key.split('.');

    let target = configuration;
    while (parts.length > 1) {
      const part = parts.shift();
      // eslint-disable-next-line no-multi-assign
      target = target[part] = target[part] || {};
    }

    target[parts[0]] = properties[key];
  });

  return configuration;
};

export const handleSiteUrl = (protocol, domain, path) => {
  let formattedProtocol = '';
  if (protocol) {
    formattedProtocol = `${protocol}://`;
  }

  switch (window.location.host) {
    case 'localhost:3001':
      return `${formattedProtocol}${domain}.dev.indexapro.com.br${path}`;
    case 'dev.indexapro.com.br':
      return `${formattedProtocol}${domain}.dev.indexapro.com.br${path}`;
    default:
      return `${formattedProtocol}${domain}.indexapro.com.br${path}`;
  }
};

export function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

export function getCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}
