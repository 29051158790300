import React from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import { ToastContainer } from 'react-toastify';

import GlobalStyle from './styles/global';

import { PublicRoutes, history } from './routes';
import store from './store';
import GlobalModal from './components/GlobalModal';
import GlobalLoading from './components/GlobalLodaing';
import CookiesConsent from './components/CookiesConsent';
import { setCookie } from './utils/utils';

const App = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const campaign = urlParams.get('utm_campaign');
  if (urlParams.get('utm_campaign')) {
    setCookie('utm_campaign', campaign);
  }

  const source = urlParams.get('utm_source');
  if (urlParams.get('utm_source')) {
    setCookie('utm_source', source);
  }

  return (
    <Provider store={store}>
      <GlobalStyle />
      <Router history={history}>
        <CookiesConsent />
        <GlobalLoading />
        <GlobalModal />
        <ToastContainer />
        <PublicRoutes />
      </Router>
    </Provider>
  );
};

export default App;
