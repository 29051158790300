import { Types } from '../actions/global';

const stateInicial = {
  loader: {
    visible: false,
  },
  modal: {
    visible: false,
    title: '',
    text: '',
  },
  siteCreation: {
    progressIndicator: 0,
  },
};

export default function setGlobalState(state = stateInicial, action) {
  switch (action.type) {
    case Types.SET_GLOBAL_STATE:
      return { ...state, ...action.global };

    default:
      return state;
  }
}
