import {
  ButtonGroup,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
// import CountUp from 'react-countup';

import { Animated } from 'react-animated-css';
import Header from '../../../components/Site/Header';
import {
  BgImage,
  ButtonMore,
  ButtonMoreContent,
  Content,
  CustomStep,
  Description,
  Feature,
  FeaturesContent,
  FeedbackCompany,
  FeedbackCompanyName,
  FeedbackContainer,
  FeedbackInfos,
  FeedbackItem,
  FeedbackName,
  FeedbackPosition,
  HomeButton,
  HowItWorksContent,
  HowItWorksItem,
  HowItWorksSection,
  MainContainer,
  SectionConatiner,
} from './styles';
import Footer from '../../../components/Site/Footer';
// import Pricing from './Pricing';
import {
  ArrowDownNormalIcon,
  ChartLineIcon,
  HandIcon,
  LockIcon,
  MessageIcon,
  MobileIcon,
  SeoIcon,
} from '../../../components/Icons/FontAwsome';
import { ButtonFilled } from '../../../components/Button';
import SocialMedias from '../../../components/SocialMedias';
import { history } from '../../../routes';
import { useIntersection } from '../../../utils/hooks';
import homeImage from '../../../assets/homeImage.webp';
import franciniImage from '../../../assets/dra-francini.webp';
import jairImage from '../../../assets/jair-rambo.webp';

const Home = () => {
  const [featuresIsVisible, setFeaturesIsVisible] = useState(false);
  const [howItWorksIsVisible, setHowItWorksIsVisible] = useState(false);

  const refFeatures = useRef();
  const featuresInViewport = useIntersection(refFeatures, '0px');

  const refHowItWorks = useRef();
  const howItWorksInViewport = useIntersection(refHowItWorks, '0px');

  useEffect(() => {
    if (!featuresIsVisible) {
      setFeaturesIsVisible(featuresInViewport);
    }

    if (!howItWorksIsVisible) {
      setHowItWorksIsVisible(howItWorksInViewport);
    }
  }, [featuresInViewport, howItWorksInViewport]);

  return (
    <>
      <SocialMedias />
      <Header />
      <MainContainer id="main">
        <BgImage />
        <Content>
          <Description>
            <h1>Crie um site para sua empresa de maneira rápida e fácil</h1>
          </Description>
          <ButtonGroup
            size="large"
            style={{ color: '#fff' }}
          >
            <HomeButton
              id="home-create-site-button"
              size="large"
              style={{ color: '#fff', border: '2px solid #fff' }}
              onClick={() => history.push('/criar-site')}
            >
              Criar site grátis
            </HomeButton>
            <HomeButton
              id="home-demonstration-button"
              size="large"
              style={{ color: '#fff', border: '2px solid #fff' }}
              href="/demonstracao"
              target="_blank"
            >
              Demonstração
            </HomeButton>
          </ButtonGroup>
        </Content>
        <ButtonMore onClick={() => window.scrollTo({ top: window.innerHeight - 64, behavior: 'smooth' })}>
          <ButtonMoreContent
            animationIn="fadeIn"
            animationInDelay={1000}
            animationInDuration={500}
            isVisible
          >
            SAIBA MAIS
            <ArrowDownNormalIcon />
          </ButtonMoreContent>
        </ButtonMore>
      </MainContainer>
      <Animated
        animationIn="fadeIn"
        isVisible={featuresIsVisible}
      >
        <SectionConatiner ref={refFeatures} id="reasons">
          <h1>Por que a Indexa?</h1>
          <FeaturesContent>
            <Feature
              animationIn="bounceInLeft"
              isVisible={featuresIsVisible}
            >
              <HandIcon size={3} />
              <div>
                <label>Visitas ilimitadas</label>
                <span>Na Indexa não limitamos o número de visitas para o seu site. Apoiamos a sua evolução desde o começo.</span>
              </div>
            </Feature>
            <Feature
              animationIn="bounceInLeft"
              isVisible={featuresIsVisible}
            >
              <MessageIcon size={3} />
              <div>
                <label>Comunique-se com seus clientes</label>
                <span>Seu site vai possuir integração com WhatsApp, página de contatos com e-mail, Facebook e Instagram.</span>
              </div>
            </Feature>
            <Feature
              animationIn="bounceInLeft"
              animationInDelay={500}
              isVisible={featuresIsVisible}
            >
              <ChartLineIcon size={3} />
              <div>
                <label>Performance</label>
                <span>Trabalhamos com tecnologias de última geração, utilizamos o que há de melhor no mercado, visando fornecer alta disponibilidade e performance ao seu site.</span>
              </div>
            </Feature>
            <Feature
              animationIn="bounceInLeft"
              animationInDelay={500}
              isVisible={featuresIsVisible}
            >
              <MobileIcon size={3} />
              <div>
                <label>Usabilidade</label>
                <span>Nos dias atuais, cerca de 80% das visitas vem de celulares, por isso deixamos o seu site pronto para ser acessado em dispositivos móveis (celulares e tablets Android/iOS) de forma amigável.</span>
              </div>
            </Feature>
            <Feature
              animationIn="bounceInLeft"
              animationInDelay={1000}
              isVisible={featuresIsVisible}
            >
              <SeoIcon size={3} />
              <div>
                <label>SEO</label>
                <span>As páginas dos nossos sites estão prontas para serem identificadas pelo Google e outras ferramentas de busca, assim seu site é facilmente encontrado por clientes.</span>
              </div>
            </Feature>
            <Feature
              animationIn="bounceInLeft"
              animationInDelay={1000}
              isVisible={featuresIsVisible}
            >
              <LockIcon size={3} />
              <div>
                <label>Certificado de segurança SSL</label>
                <span>Seu site já vem com certificado de segurança configurado, basta ativá-lo.</span>
              </div>
            </Feature>
          </FeaturesContent>
          <ButtonFilled
            variant="contained"
            color="primary"
            id="home-reasons-create-site-button"
            onClick={() => history.push('/criar-site')}
          >
            CRIE SEU SITE
          </ButtonFilled>
        </SectionConatiner>
      </Animated>
      <HowItWorksSection ref={refHowItWorks} id="how-it-works">
        <HowItWorksContent animationIn="fadeInUp" isVisible={howItWorksIsVisible}>
          <HowItWorksItem>
            <div>
              <h1>Como funciona?</h1>
              <label>Crie um site sem ter conhecimento técnico.</label>
              <span>Com a Indexa você não precisa ter conhecimento na área de programação para criar o seu site, basta conhecer a sua empresa.</span>
            </div>
          </HowItWorksItem>
          <HowItWorksItem>
            <img src={homeImage} alt="" />
          </HowItWorksItem>
          <HowItWorksItem>
            <div>
              <CustomStep>1</CustomStep>
              <label>Crie uma conta Indexa.</label>
              <span>A criação de conta é gratuita, você utilizará essa conta para acessar a plataforma, customizar seu site e muito mais.</span>
            </div>
          </HowItWorksItem>
          <HowItWorksItem>
            <div>
              <CustomStep>2</CustomStep>
              <label>Informe os dados da sua empresa.</label>
              <span>Após a criação da conta, você será direcionado para um formulário onde deverá informar os dados da sua empresa que serão exibidos em seu site.</span>
            </div>
          </HowItWorksItem>
          <HowItWorksItem>
            <div>
              <CustomStep>3</CustomStep>
              <label>Pronto, seu site já está criado!</label>
              <span>Ao término dos formulários, em menos de 10 segundos vamos criar o site para sua empresa com base nas informações que você nos disponibilizou.</span>
            </div>
          </HowItWorksItem>
          <HowItWorksItem>
            <div>
              <CustomStep>4</CustomStep>
              <label>Painel do cliente Indexa.</label>
              <span>Agora com um site criado, você poderá acessar o painel do cliente Indexa para administrar seu site, mudar a aparência, editar informações e realizar integrações.</span>
            </div>
          </HowItWorksItem>
        </HowItWorksContent>
      </HowItWorksSection>
      <SectionConatiner
        id="clients"
      >
        <FeedbackContainer>
          <h1>Feedback dos nossos clientes</h1>
          <FeedbackItem>
            <FeedbackInfos>
              <img src={franciniImage} alt="" />
              <span>&quot;Minha experiência com a Indexa foi, desde o início, muito satisfatória. O que me chamou a atenção no primeiro momento foi a facilidade de poder criar o meu próprio site e depois poder ir escolhendo o template que eu quisesse. Sempre achei super intuitivo e nunca tive nenhuma dúvida sobre os processos para criação e alteração do meu site.&quot;</span>
            </FeedbackInfos>
            <FeedbackCompany>
              <FeedbackName>Dra. Francini Pereira</FeedbackName>
              <FeedbackPosition>Empresária do ramo estético</FeedbackPosition>
              <a
                href="https://francinipereira.com"
                target="_blank"
                rel="noreferrer"
              >
                <FeedbackCompanyName>Francini Pereira - Biomedicina estética</FeedbackCompanyName>
              </a>
            </FeedbackCompany>
          </FeedbackItem>

          <hr />

          <FeedbackItem>
            <FeedbackInfos>
              <span>&quot;Achei a criação do site muito rápida, com poucos passos eu já tinha o site da minha instaladora pronto. Melhor relação custo x benefício que encontrei para a minha necessidade. Recomendo!&quot;</span>
              <img src={jairImage} alt="" />
            </FeedbackInfos>
            <FeedbackCompany alignItens="flex-start">
              <FeedbackName>Jair Rambo</FeedbackName>
              <FeedbackPosition>Empresário do ramo de climatização</FeedbackPosition>
              <a
                href="https://ramboinstalacoes.com.br"
                target="_blank"
                rel="noreferrer"
              >
                <FeedbackCompanyName>Rambo Instalações</FeedbackCompanyName>
              </a>
            </FeedbackCompany>
          </FeedbackItem>
        </FeedbackContainer>
      </SectionConatiner>
      <Footer />
    </>
  );
};

export default Home;
