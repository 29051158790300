import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

export const HeaderContainer = styled(AppBar)`
  &&{
    position: fixed;
    background: #201b28;
    color: #f4f4f4;

    a {
      cursor: pointer;
    }
  }
`;

export const HeaderContent = styled(Toolbar)`
  &&{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0;
    padding-left: 20px;

    img {
      height: 1.1rem;
      width: auto;
    }

    @media (max-width: 1250px) {
      padding-left: 10px;
    }
  }
`;

export const HeaderActionMenu = styled.div`
  display: flex;

  * {
    margin-right: 2rem;
  }

  @media (max-width: 1250px) {
    display: none;
  }
`;
