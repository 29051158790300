import api from './api';

const header = () => ({
  headers: { Authorization: `Bearer ${localStorage.getItem('access-token')}` },
});

export const createUser = async user => {
  const userData = await api.post('/user', user);

  return userData.data;
};

export const getUser = async () => {
  const userData = await api.get('/user', header());

  return userData.data;
};

export const updateUser = async (user, action) => {
  let path = '/user';

  if (action) {
    path = `/user?action=${action}`;
  }

  const userData = await api.put(path, user, header());

  return userData.data;
};

export const changePassword = async (currentPassword, newPassword) => {
  const path = '/user/change-password';

  const userData = await api.put(path, { currentPassword, newPassword }, header());

  return userData.data;
};

export const forgotPassword = async email => {
  const userData = await api.post('/user/forgot-password', { email });

  return userData.data;
};

export const resetPassword = async ({ tokenCheck, newPassword }) => {
  let path = '/user/reset-password';

  const urlParams = new URLSearchParams(window.location.search);
  const passwordResetToken = urlParams.get('passwordResetToken');

  if (tokenCheck) {
    path = '/user/reset-password?action=token-check';
  }

  const userData = await api.post(path, { passwordResetToken, newPassword });

  return userData.data;
};
