export const Types = {
  SET_GLOBAL_STATE: 'global/SET_GLOBAL_STATE',
};

export const Actions = {
  setGlobalStateRequest: data => ({
    type: Types.SET_GLOBAL_STATE,
    global: { ...data },
  }),
};
