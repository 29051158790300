import React from 'react';
import SocialMediasMobile from '../../SocialMedias/Mobile';

import { FooterContainer, FooterContent } from './styles';

const Footer = () => (
  <FooterContainer>
    <SocialMediasMobile />
    <FooterContent>
      <div>
        Indexa
      </div>
      <div>
        Todos os direitos reservados
      </div>
      <div>
        {new Date().getFullYear()}
      </div>
    </FooterContent>
  </FooterContainer>
);

export default Footer;
