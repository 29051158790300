import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';
import { Actions as GlobalActions } from '../../store/actions/global';
import { DescriptionText } from './styles';

export default function GlobalModal() {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { visible, text, title } = useSelector(state => state.global.modal);
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const currentUrl = window.location.href;

    if (currentUrl.includes('criar-site?resume=true')) {
      dispatch(GlobalActions.setGlobalStateRequest({
        modal: {
          visible: true,
          text: 'Identificamos que você ainda não terminou de informar todos os dados que precisamos para criar o seu site, vamos recuprar suas últimas informações para que você continue de onde parou.',
          title: 'Bem-vindo(a) de volta!',
        },
      }));
    }

    if (currentUrl.includes('/dashboard/home') && currentUrl.includes('collection_status=')) {
      const params = new URLSearchParams(search);
      const status = params.get('collection_status');

      if (status === 'in_process') {
        dispatch(GlobalActions.setGlobalStateRequest({
          modal: {
            visible: true,
            text: 'Logo logo seu site estará público para toda a internet e poderá ser acessado por seus clientes. Você será notificado por e-mail quando o pagamento for aprovado.',
            title: 'Estamos processando seu pagamento!',
          },
        }));
      }

      if (status === 'approved') {
        dispatch(GlobalActions.setGlobalStateRequest({
          modal: {
            visible: true,
            text: 'Agora seu site está público para toda a internet e pode ser acessado por seus clientes, além disso, seu site logo começará a ser listado no Google e outras ferramentas de busca.',
            title: 'Recebemos seu pagamento, obrigado!',
          },
        }));
      }
    }
  }, [search]);

  if (!visible) {
    return <></>;
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DescriptionText>{text}</DescriptionText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
