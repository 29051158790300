import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const ButtonFit = styled(Button)`
  && {
    width: 100%;
    margin-top: 0.25rem;
    span {
      text-transform: none;
    }
  }
`;
