import React from 'react';

import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Background, Spinner } from './styles';

const GlobalLoading = () => {
  const { visible } = useSelector(state => state.global.loader);

  if (!visible) {
    return <></>;
  }

  return (
    <Spinner>
      <Background />
      <CircularProgress />
    </Spinner>
  );
};

export default GlobalLoading;
