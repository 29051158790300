import React, { Suspense, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useDispatch, useSelector } from 'react-redux';

import HomeSite from '../pages/Site/Home';
import NotFoundLoader from '../components/NotFoundLoader';
import { Actions as UserActions } from '../store/actions/user';
import { useSession } from '../utils/hooks';
import Loading from '../components/Loading';
import { CustomRoute } from './customRoute';

const Login = React.lazy(() => import('../pages/Login'));
const Demonstration = React.lazy(() => import('../pages/Site/Demonstration'));
const ForgotPassword = React.lazy(() => import('../pages/Login/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../pages/Login/ResetPassword'));
const Terms = React.lazy(() => import('../pages/Site/Terms'));
const Cookies = React.lazy(() => import('../pages/Site/Cookies'));
const PrivacyTerms = React.lazy(() => import('../pages/Site/PrivacyTerms'));
const SiteWizard = React.lazy(() => import('../pages/SiteWizard'));
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const PartnerDashboard = React.lazy(() => import('../pages/PartnerDashboard'));
const AdminDashboard = React.lazy(() => import('../pages/AdminDashboard'));

export const history = createBrowserHistory();

export const PublicRoutes = () => {
  const dispatch = useDispatch();
  const { permission } = useSelector(state => state.user.data);

  const isLoggedIn = useSession();
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(UserActions.getUserRequest());
    }
  }, [isLoggedIn]);

  return (
    <Switch>
      <CustomRoute
        exact
        path="/"
        render={() => <HomeSite />}
        title="Indexa | Crie um site para sua empresa | Rápido e fácil"
        content="Com o criador de sites Indexa, você cria um site para sua empresa em minutos. A criação do site é grátis e você só paga se gostar do nosso serviço"
      />
      <CustomRoute
        exact
        path="/logout"
        render={() => localStorage.removeItem('user')}
      />
      <CustomRoute
        exact
        path="/login"
        render={() => <Suspense fallback={<Loading />}><Login /></Suspense>}
        title="Entrar na Indexa"
        content="Acesse sua conta Indexa"
      />
      <CustomRoute
        exact
        path="/recuperar-senha"
        render={() => <Suspense fallback={<Loading />}><ForgotPassword /></Suspense>}
        title="Recuperar senha"
        content="Recupere a senha da sua conta Indexa"
      />
      <CustomRoute
        exact
        path="/demonstracao"
        render={() => <Suspense fallback={<Loading />}><Demonstration /></Suspense>}
        title="Modelos de sites"
        content="Confira todos os modelos de sites que o criador de sites da Indexa pode te oferecer gratuitamente"
      />
      <CustomRoute
        exact
        path="/termos-de-uso"
        render={() => <Suspense fallback={<Loading />}><Terms /></Suspense>}
        title="Termos de uso"
        content="Conulte os termos de uso da Indexa"
      />
      <CustomRoute
        exact
        path="/criar-site"
        render={() => <Suspense fallback={<Loading />}><SiteWizard /></Suspense>}
        title="Criar site grátis"
        content="Preenchendo o nosso formulário seu site ficará pronto em segundos"
      />
      <CustomRoute
        exact
        path="/redefinir-senha"
        render={() => <Suspense fallback={<Loading />}><ResetPassword /></Suspense>}
      />
      <CustomRoute
        exact
        path="/politica-de-privacidade"
        render={() => <Suspense fallback={<Loading />}><PrivacyTerms /></Suspense>}
      />
      <CustomRoute
        exact
        path="/politica-de-cookies"
        render={() => <Suspense fallback={<Loading />}><Cookies /></Suspense>}
      />

      {(permission !== 'admin' && permission !== 'partner') && (
        <CustomRoute
          privateRoute
          path="/dashboard"
          render={() => <Suspense fallback={<Loading />}><Dashboard /></Suspense>}
          title="Painel do Cliente"
          content="Acesse seu Painel do Cliente Indexa"
        />
      )}
      {permission === 'admin' && (
        <CustomRoute
          privateRoute
          path="/admin"
          render={() => <Suspense fallback={<Loading />}><AdminDashboard /></Suspense>}
        />
      )}
      {permission === 'partner' && (
        <CustomRoute
          privateRoute
          path="/parceiro"
          render={() => <Suspense fallback={<Loading />}><PartnerDashboard /></Suspense>}
        />
      )}
      <CustomRoute
        path="/"
        render={() => <NotFoundLoader />}
      />
    </Switch>
  );
};
