import React, { useMemo, useState } from 'react';
// import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@material-ui/core';
import { history } from '../../../routes';
import { Actions as SessionActions } from '../../../store/actions/session';
import HeaderAvatar from '../../HeaderAvatar';

export default function HeaderMenu({ dashboard = true }) {
  const disptach = useDispatch();
  const { permission } = useSelector(state => state.user.data);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    disptach(SessionActions.logoutUserRequest());
    handleClose();
  };

  const dashboardUrl = useMemo(() => {
    switch (permission) {
      case 'admin':
        return '/admin/home';
      case 'partner':
        return '/parceiro/home';
      default:
        return '/dashboard/home';
    }
  });

  return (
    <div>
      <Button
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        style={{ height: 'auto' }}
      >
        <HeaderAvatar />
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {dashboard && (
          <MenuItem onClick={() => {
            handleClose();
            history.push(dashboardUrl);
          }}
          >
            Acessar painel
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>Sair</MenuItem>
      </Menu>
    </div>
  );
}
