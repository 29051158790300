import React from 'react';
import {
  FacebookIcon, InstagramIcon, WhatsAppIcon, YoutubeIcon,
} from '../../Icons/FontAwsome';

import { Container } from './styles';

const SocialMediasMobile = () => (
  <Container>
    <div className="wrapper">
      <ul>
        <li className="facebook">
          <a
            href="https://facebook.com/indexapro"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon size={1.5} color="#fff" />
          </a>
        </li>

        <li className="instagram">
          <a
            href="https://instagram.com/indexapro"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon size={1.5} color="#fff" />
          </a>
        </li>

        <li className="whatsapp">
          <a
            href="https://api.whatsapp.com/send?l=pt_br&phone=551120504981"
            target="_blank"
            rel="noreferrer"
          >
            <WhatsAppIcon size={1.5} color="#fff" />
          </a>
        </li>

        <li className="youtube">
          <a
            href="https://youtube.com/@indexapro"
            target="_blank"
            rel="noreferrer"
          >
            <YoutubeIcon size={1.5} color="#fff" />
          </a>
        </li>
      </ul>
    </div>
  </Container>
);

export default SocialMediasMobile;
